import {cn} from '@skillrecordings/ui/utils/cn'

export const SpiralIllustration = ({className}: {className?: string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   width="480"
      //   height="480"
      className={cn(className)}
      fill="none"
      viewBox="0 0 480 480"
    >
      <path
        fill="#EB5228"
        d="M441.133 300.454c.973-2.398 3.882-3.4 6.201-2.141 16.923 9.175 24.712 29.321 17.468 47.185-7.245 17.865-26.995 27.214-45.695 22.422-2.564-.655-3.974-3.351-3.001-5.748l25.027-61.718Zm12.667-97.567c-.214-1.348.836-2.574 2.235-2.614 10.21-.304 19.267 6.801 20.859 16.845 1.593 10.044-4.834 19.523-14.653 22.29-1.345.38-2.725-.473-2.938-1.821l-5.503-34.7ZM293.695 37.228a4.928 4.928 0 0 1 1.852 7.233c-12.525 18.106-36.932 24.705-57.082 14.581-20.15-10.124-29.012-33.438-21.43-54.026 1.037-2.823 4.342-4.121 7.046-2.762l69.614 34.974ZM22.306 163.032c-.818 1.394-2.7 1.758-4.01.781-9.565-7.128-12.504-20.227-6.406-30.611 6.097-10.384 19.103-14.431 30.163-9.85 1.517.627 2.136 2.411 1.318 3.805l-21.065 35.875Z"
      />
      <path
        fill="#FDB854"
        d="M403.132 65.876c0 10.135-8.357 18.352-18.665 18.352s-18.665-8.217-18.665-18.352c0-10.136 8.357-18.353 18.665-18.353s18.665 8.217 18.665 18.353Z"
      />
      <path
        fill="#5069FF"
        d="M461.561 169.605c0 15.424-12.717 27.928-28.403 27.928-15.687 0-28.403-12.504-28.403-27.928 0-15.424 12.716-27.927 28.403-27.927 15.686 0 28.403 12.503 28.403 27.927Z"
      />
      <path
        fill="#FDB854"
        d="M360.934 456.86c0 12.78-10.537 23.14-23.534 23.14-12.997 0-23.534-10.36-23.534-23.14 0-12.78 10.537-23.14 23.534-23.14 12.997 0 23.534 10.36 23.534 23.14Zm86.019-230.602c0 10.576-8.719 19.15-19.476 19.15-10.756 0-19.476-8.574-19.476-19.15 0-10.576 8.72-19.15 19.476-19.15 10.757 0 19.476 8.574 19.476 19.15ZM313.866 75.45c0 8.373-6.903 15.16-15.419 15.16-8.515 0-15.418-6.787-15.418-15.16 0-8.373 6.903-15.16 15.418-15.16 8.516 0 15.419 6.787 15.419 15.16Zm113.611 232.199c0 6.169-5.086 11.171-11.361 11.171-6.274 0-11.361-5.002-11.361-11.171 0-6.17 5.087-11.171 11.361-11.171 6.275 0 11.361 5.001 11.361 11.171Zm-12.984 82.983c0 11.458-9.446 20.746-21.099 20.746s-21.099-9.288-21.099-20.746c0-11.458 9.446-20.746 21.099-20.746s21.099 9.288 21.099 20.746ZM145.072 23.585c0 7.051-5.813 12.767-12.984 12.767s-12.984-5.716-12.984-12.767c0-7.05 5.813-12.767 12.984-12.767s12.984 5.716 12.984 12.767Zm-84.397 85.378c0 5.729-4.723 10.373-10.55 10.373-5.826 0-10.549-4.644-10.549-10.373 0-5.729 4.723-10.373 10.55-10.373 5.826 0 10.55 4.644 10.55 10.373Z"
      />
      <path
        fill="#5069FF"
        d="M102.874 90.61c0 7.052-5.813 12.767-12.984 12.767s-12.984-5.715-12.984-12.766 5.813-12.767 12.984-12.767c7.17 0 12.984 5.716 12.984 12.767Z"
      />
      <path
        fill="#FDB854"
        d="M211.616 40.342c0 16.305-13.443 29.523-30.026 29.523s-30.026-13.218-30.026-29.523c0-16.306 13.443-29.524 30.026-29.524s30.026 13.218 30.026 29.524Z"
      />
      <path
        fill="#2BC370"
        d="M321.981 99.387V84.151c0-2.6 2.147-4.71 4.792-4.71h30.992c2.645 0 4.792 2.11 4.792 4.71v15.104c0 9.757-7.097 18.246-16.891 19.81-12.707 2.026-23.685-7.566-23.685-19.678Zm-77.802 334.49 15.374 1.904c2.624.326 4.485 2.684 4.155 5.264l-3.875 30.234c-.331 2.581-2.729 4.411-5.353 4.086l-15.24-1.888c-9.845-1.22-17.524-9.204-17.878-18.954-.455-12.65 10.596-22.161 22.817-20.646Zm137.854-88.728v-12.798c0-2.185 1.803-3.957 4.025-3.957h26.033c2.222 0 4.025 1.772 4.025 3.957v12.687c0 8.196-5.961 15.327-14.188 16.641-10.674 1.701-19.895-6.356-19.895-16.53ZM103.218 70.002l-3.982-15.435c-.68-2.634.944-5.315 3.623-5.983l31.398-7.83c2.68-.669 5.406.927 6.086 3.562l3.947 15.301c2.549 9.885-2.421 20.278-11.935 24.337-12.344 5.263-25.972-1.68-29.137-13.952Zm248.237-15.487-8.099 13.79c-1.382 2.354-4.447 3.16-6.841 1.801l-28.05-15.928c-2.394-1.36-3.215-4.373-1.833-6.726l8.029-13.67c5.187-8.831 16.122-12.868 25.818-9.25 12.578 4.697 17.415 19.02 10.976 29.983Z"
      />
      <path
        fill="#EB5228"
        d="m432.183 118.674 6.453-5.3c1.102-.905 2.709-.8 3.587.234l10.291 12.115c.878 1.034.697 2.608-.405 3.512l-6.398 5.254c-4.133 3.394-10.085 3.573-13.999.289-5.078-4.263-4.66-11.891.471-16.104Z"
      />
      <path
        fill="#2BC370"
        d="m392.885 426.912 2.154 10.013c.367 1.709-.745 3.39-2.484 3.751l-20.368 4.237c-1.738.361-3.448-.733-3.815-2.442l-2.136-9.926c-1.379-6.412 2.085-12.962 8.3-15.329 8.065-3.068 16.636 1.736 18.349 9.696Z"
      />
      <rect
        width="26.46"
        height="56.695"
        fill="#5069FF"
        rx="3.504"
        transform="matrix(.3155 .94893 .95203 -.30602 415.16 267.031)"
      />
      <rect
        width="26.668"
        height="56.259"
        fill="#5069FF"
        rx="3.504"
        transform="matrix(.75168 .65953 .67204 -.74052 265.176 451.443)"
      />
      <rect
        width="47.067"
        height="46.28"
        fill="#5069FF"
        rx="7.008"
        transform="matrix(-.99983 .01825 .01888 .99982 420.377 94.809)"
      />
      <rect
        width="25.109"
        height="25.508"
        fill="#5069FF"
        rx="3.504"
        transform="matrix(-.18487 .98276 .98386 .17893 49.087 65.077)"
      />
      <rect
        width="18.796"
        height="19.094"
        fill="#5069FF"
        rx="2.628"
        transform="matrix(-.18487 .98276 .98386 .17893 213.468 322.01)"
      />
      <rect
        width="15.244"
        height="15.486"
        fill="#5069FF"
        rx="2.628"
        transform="matrix(-.18487 .98276 .98386 .17893 289.75 323.607)"
      />
      <path
        fill="#EB5228"
        d="M100.309 366.983c1.124 1.573 3.402 1.812 4.856.512 10.616-9.476 12.706-25.368 4.334-37.091-8.372-11.724-24.283-15.188-36.952-8.588-1.737.904-2.283 3.091-1.16 4.665l28.922 40.502Zm57.129-181.9c.83-.638.898-1.85.146-2.584-5.48-5.357-14.236-6.024-20.422-1.27s-7.644 13.27-3.681 19.792a1.77 1.77 0 0 0 2.585.485l21.372-16.423Z"
      />
      <path
        fill="#FDB854"
        d="M129.215 432.716c6.648-.92 11.278-6.964 10.343-13.5-.935-6.537-7.082-11.09-13.73-10.171-6.648.92-11.279 6.964-10.343 13.501.935 6.536 7.082 11.09 13.73 10.17Zm73.814-103.463c3.994-.553 6.776-4.184 6.214-8.111-.562-3.927-4.255-6.662-8.249-6.11-3.993.553-6.776 4.184-6.214 8.111.562 3.927 4.255 6.662 8.249 6.11Zm-2.32 131.291c9.972-1.38 16.918-10.447 15.515-20.251-1.403-9.805-10.623-16.635-20.595-15.256-9.972 1.38-16.918 10.446-15.515 20.251 1.403 9.805 10.624 16.635 20.595 15.256Zm-74.272-84.774c5.401-.748 9.164-5.659 8.404-10.97-.76-5.31-5.755-9.01-11.156-8.263-5.401.747-9.164 5.658-8.404 10.969.76 5.311 5.755 9.011 11.156 8.264Zm264.79-207.11c5.401-.747 9.163-5.658 8.404-10.969-.76-5.311-5.755-9.011-11.156-8.263-5.402.747-9.164 5.658-8.404 10.969.76 5.311 5.754 9.01 11.156 8.263Zm-175.194 27.117c5.401-.747 9.163-5.659 8.403-10.969-.759-5.311-5.754-9.011-11.155-8.264-5.402.747-9.164 5.659-8.404 10.969.76 5.311 5.754 9.011 11.156 8.264Zm-13.061 115.3c4.669-.645 7.921-4.89 7.264-9.481-.657-4.59-4.974-7.788-9.643-7.142-4.668.645-7.921 4.89-7.264 9.481.657 4.59 4.974 7.788 9.643 7.142Zm37.854-45.436c2.796-.387 4.743-2.929 4.35-5.679-.393-2.749-2.979-4.665-5.775-4.278-2.796.387-4.744 2.929-4.35 5.679.393 2.749 2.979 4.665 5.775 4.278ZM302.5 471.903c2.796-.387 4.744-2.929 4.35-5.679-.393-2.749-2.979-4.665-5.775-4.278-2.796.387-4.743 2.929-4.35 5.679.393 2.749 2.979 4.665 5.775 4.278ZM93.131 376.151c2.796-.386 4.744-2.929 4.35-5.678-.393-2.75-2.978-4.665-5.775-4.278-2.796.386-4.743 2.929-4.35 5.678.394 2.75 2.98 4.665 5.775 4.278Z"
      />
      <path
        fill="#EB5228"
        d="M215.041 472.412c2.796-.386 4.743-2.929 4.35-5.678-.394-2.75-2.979-4.665-5.775-4.278-2.796.386-4.744 2.929-4.35 5.678.393 2.75 2.979 4.665 5.775 4.278Z"
      />
      <path
        fill="#FDB854"
        d="M247.653 263.039c1.423-.196 2.74.779 2.94 2.178.2 1.4-.791 2.694-2.215 2.891-1.423.197-2.739-.778-2.939-2.178-.201-1.399.791-2.694 2.214-2.891Zm-.905 9.617c2.373-.328 4.566 1.297 4.9 3.63.334 2.332-1.319 4.489-3.691 4.817-2.372.328-4.565-1.296-4.899-3.629-.334-2.333 1.318-4.49 3.69-4.818ZM78.305 275.31c4.57-.632 7.754-4.788 7.111-9.282-.642-4.494-4.869-7.624-9.44-6.992-4.57.632-7.753 4.788-7.11 9.282.643 4.494 4.87 7.624 9.44 6.992Zm48.05-60.961c3.74-.517 6.345-3.917 5.818-7.594-.526-3.676-3.984-6.238-7.723-5.72-3.739.517-6.344 3.917-5.818 7.594.526 3.676 3.984 6.238 7.723 5.72Zm-8.226 28.296c4.57-.633 7.754-4.788 7.111-9.282-.643-4.494-4.869-7.624-9.44-6.992-4.57.632-7.754 4.788-7.111 9.282.643 4.494 4.87 7.624 9.44 6.992Z"
      />
      <path
        fill="#5069FF"
        d="M94.765 315.277c10.387-1.437 17.623-10.881 16.162-21.095-1.462-10.213-11.067-17.328-21.454-15.891-10.387 1.437-17.623 10.882-16.161 21.095 1.461 10.213 11.066 17.328 21.453 15.891Z"
      />
      <path
        fill="#2BC370"
        d="m143.081 379.503-10.343 1.431c-1.765.244-3.007 1.773-2.773 3.412l2.749 19.21c.235 1.64 1.858 2.772 3.623 2.528l10.253-1.418c6.623-.917 11.756-6.112 11.95-12.33.248-8.067-7.237-13.97-15.459-12.833Zm-38.616-134.728L94.1 243.664c-1.77-.19-3.362 1.068-3.554 2.808l-2.261 20.385c-.193 1.739 1.086 3.305 2.855 3.495l10.276 1.102c6.637.711 12.931-3.337 14.709-9.665 2.305-8.21-3.42-16.13-11.66-17.014Z"
      />
      <path
        fill="#5069FF"
        d="m116.69 401.778 8.214-6.314a3.136 3.136 0 0 0 .555-4.45l-12.844-16.155c-1.096-1.379-3.124-1.623-4.526-.546l-8.143 6.26c-5.26 4.043-6.897 11.26-3.682 17.014 4.174 7.463 13.895 9.211 20.426 4.191Z"
      />
      <path
        fill="#2BC370"
        d="m182.382 196.629 8.214-6.314a3.135 3.135 0 0 0 .555-4.45l-12.844-16.155c-1.096-1.379-3.124-1.623-4.526-.546l-8.143 6.26c-5.261 4.043-6.897 11.26-3.682 17.014 4.174 7.464 13.895 9.211 20.426 4.191Z"
      />
      <rect
        width="30.252"
        height="29.847"
        fill="#5069FF"
        rx="3.504"
        transform="matrix(.94998 -.31232 .32194 .94676 143.032 415.375)"
      />
      <rect
        width="16.404"
        height="16.185"
        fill="#5069FF"
        rx="5.256"
        transform="matrix(.94998 -.31232 .32194 .94676 96.416 211.284)"
      />
      <path
        fill="#EB5228"
        d="M291.963 194.235a2.488 2.488 0 0 0-3.632.674c-5.579 9.151-3.553 21.113 5.123 27.802s20.973 5.772 28.681-1.738a2.395 2.395 0 0 0-.2-3.628l-29.972-23.11Zm-87.503 79.671c.431-.898-.043-1.965-1.008-2.274-7.039-2.261-14.826.88-18.037 7.571-3.21 6.692-.723 14.598 5.529 18.501.856.536 1.994.217 2.425-.681l11.091-23.117Zm76.084 44.921a1.294 1.294 0 0 0 .47 1.903c5.163 2.7 11.654 1.298 15.076-3.478 3.423-4.776 2.581-11.259-1.742-15.13a1.342 1.342 0 0 0-1.981.206l-11.823 16.499Z"
      />
      <path
        fill="#5069FF"
        d="M312.317 308.236c-.473.99.051 2.163 1.115 2.502 7.757 2.476 16.327-1 19.848-8.377 3.521-7.376.765-16.079-6.129-20.365-.945-.588-2.197-.234-2.67.756l-12.164 25.484Z"
      />
      <path
        fill="#EB5228"
        d="M242.711 325.756a1.873 1.873 0 0 0-2.772.181c-5.003 6.333-4.59 15.439 1.271 21.201 5.86 5.762 15.121 6.169 21.562 1.25.883-.674.97-1.953.184-2.726l-20.245-19.906Z"
      />
      <path
        fill="#FDB854"
        d="M252.475 154.59c-4.61 2.098-6.618 7.474-4.485 12.007 2.134 4.534 7.601 6.508 12.212 4.41 4.61-2.098 6.618-7.473 4.485-12.007-2.134-4.533-7.601-6.508-12.212-4.41Zm-57.988-4.76c-6.916 3.147-9.928 11.211-6.728 18.011 3.201 6.8 11.402 9.762 18.317 6.615 6.916-3.147 9.928-11.21 6.728-18.011-3.2-6.8-11.401-9.761-18.317-6.615Z"
      />
      <path
        fill="#5069FF"
        d="M146.234 200.039c-6.916 3.147-9.928 11.211-6.728 18.011 3.201 6.8 11.402 9.762 18.318 6.615 6.915-3.147 9.928-11.211 6.727-18.011-3.2-6.8-11.401-9.762-18.317-6.615Z"
      />
      <path
        fill="#FDB854"
        d="M267.173 195.243c-3.746 1.704-5.377 6.072-3.644 9.756 1.734 3.683 6.176 5.287 9.922 3.583 3.746-1.705 5.378-6.073 3.644-9.756-1.734-3.684-6.176-5.288-9.922-3.583Zm57.243 62.405c-3.169 1.442-4.55 5.138-3.083 8.255 1.467 3.117 5.226 4.475 8.396 3.033 3.17-1.443 4.55-5.139 3.083-8.256-1.467-3.117-5.226-4.474-8.396-3.032Zm-21.226 54.507c-2.593 1.18-3.723 4.204-2.522 6.755 1.2 2.55 4.275 3.661 6.869 2.481 2.593-1.18 3.723-4.204 2.522-6.755-1.2-2.55-4.275-3.661-6.869-2.481Zm-.344-22.269c-3.17 1.443-4.55 5.139-3.083 8.256 1.467 3.117 5.226 4.474 8.396 3.032 3.17-1.443 4.55-5.139 3.083-8.256-1.467-3.117-5.226-4.474-8.396-3.032Zm.745-57.644c-7.204 3.277-10.342 11.677-7.008 18.761 3.334 7.083 11.877 10.168 19.081 6.89 7.204-3.278 10.341-11.677 7.007-18.761-3.333-7.083-11.876-10.168-19.08-6.89Z"
      />
      <path
        fill="#5069FF"
        d="m251.039 196.124 7.173-3.264c1.224-.557 1.784-1.932 1.249-3.069l-6.271-13.323c-.535-1.137-1.963-1.608-3.187-1.051l-7.111 3.236c-4.594 2.09-7.155 6.959-5.91 11.505 1.618 5.897 8.354 8.561 14.057 5.966Z"
      />
      <path
        fill="#2BC370"
        d="m312.27 285.401 7.756-1.427c1.323-.244 2.197-1.498 1.949-2.8l-2.903-15.253c-.248-1.302-1.524-2.161-2.848-1.917l-7.688 1.415c-4.967.914-8.624 5.202-8.503 10.169.16 6.444 6.071 10.948 12.237 9.813Zm-43.83-111.097-4.544 6.343a2.375 2.375 0 0 0 .59 3.343l12.902 8.936a2.467 2.467 0 0 0 3.4-.58l4.505-6.288c2.91-4.061 2.487-9.642-1.124-13.117-4.686-4.508-12.117-3.68-15.729 1.363Zm-66.731 84.048-3.59 5.011a1.876 1.876 0 0 0 .466 2.641l10.192 7.059a1.95 1.95 0 0 0 2.686-.458l3.558-4.967c2.299-3.209 1.965-7.617-.887-10.363-3.702-3.56-9.572-2.906-12.425 1.077Z"
      />
      <rect
        width="22.805"
        height="22.678"
        fill="#5069FF"
        rx="7.008"
        transform="matrix(-.82058 .57153 -.58452 -.81138 246.329 170.128)"
      />
      <rect
        width="11.853"
        height="11.788"
        fill="#5069FF"
        rx="1.752"
        transform="matrix(-.82058 .57153 -.58452 -.81138 234.349 256.507)"
      />
      <path
        fill="#2BC370"
        d="m273.56 321.726 8.084 1.002c1.38.171 2.359 1.411 2.185 2.768l-2.037 15.899c-.174 1.357-1.435 2.32-2.815 2.149l-8.015-.993c-5.177-.642-9.215-4.84-9.401-9.968-.24-6.652 5.572-11.653 11.999-10.857Z"
      />
      <path
        fill="#EB5228"
        d="m183.415 303.472 4.812.596c.821.102 1.404.84 1.301 1.647l-1.213 9.464a1.496 1.496 0 0 1-1.676 1.279l-4.77-.591c-3.082-.382-5.486-2.881-5.596-5.933-.143-3.959 3.316-6.936 7.142-6.462Z"
      />
      <rect
        width="11.36"
        height="11.539"
        fill="#5069FF"
        rx="5.256"
        transform="matrix(-.18487 .98276 .98386 .17893 28.448 163.985)"
      />
      <rect
        width="11.36"
        height="11.539"
        fill="#5069FF"
        rx="5.256"
        transform="matrix(-.18487 .98276 .98386 .17893 82.251 54.302)"
      />
      <rect
        width="8.016"
        height="8.143"
        fill="#FDB854"
        rx="4.008"
        transform="matrix(-.18487 .98276 .98386 .17893 14.843 171.985)"
      />
      <rect
        width="19.972"
        height="20.289"
        fill="#5069FF"
        rx="5.256"
        transform="matrix(-.18487 .98276 .98386 .17893 288.309 8)"
      />
      <rect
        width="11.36"
        height="11.539"
        fill="#5069FF"
        rx="5.256"
        transform="matrix(-.18487 .98276 .98386 .17893 299.736 183.566)"
      />
      <rect
        width="8.842"
        height="8.982"
        fill="#5069FF"
        rx="4.421"
        transform="matrix(-.18487 .98276 .98386 .17893 327.327 226.656)"
      />
      <rect
        width="38.664"
        height="39.322"
        fill="#5069FF"
        rx="5.256"
        transform="matrix(-.01248 .99992 .99993 .01206 324.087 387.44)"
      />
    </svg>
  )
}
